import { get_paginated_json } from './api_utils';
import {
  Voto, ThreadLista, FiltroVoto,
  FiltroProblemaThread
} from '../main/api/types';


export async function get_difficolta(filtro: FiltroVoto) {
  return get_paginated_json<Voto>(
    `/api/problems/problemi/voto-difficolta/`, filtro);
}

export async function get_bellezza(filtro: FiltroVoto) {
  return get_paginated_json<Voto>(
    `/api/problems/problemi/voto-bellezza/`, filtro);
}

export async function get_problema_thread_list(filtro?: FiltroProblemaThread) {
  return get_paginated_json<ThreadLista>('/api/problems/problemi/', filtro);
}
