import {
  get_bellezza, get_difficolta,
  get_problema_thread_list,
} from './api';
import { UserWhoami } from '../main/api/types';

// import { onopen, onclose } from '../django-rest-react';
// import { ws_base_url } from '../utils/ws';

// function workerFunc() {
//     function onmessage(e) {
//         console.log("messaggio: ", e);
//         var cusumano = new Notification("maledettooo");
//     }

//     self.onconnect = function(e) {
//         for (var i = 0, l = e.ports.length; i < l; i++) {
//             e.ports[i].addEventListener('message', onmessage);
//             e.ports[i].start(); // Required when using addEventListener. Otherwise called implicitly by onmessage setter.
//         }
//     }
// }

function get_problema_notifications_ws(): WebSocket {
  const ws_base_url = 'ws://127.0.0.1:8000';
  // Qui dobbiamo dargli in qualche modo i cookie e settare in modo meno merdoso
  // location.host eccetera.
  const ret = new WebSocket(
    ws_base_url + '/ws/problems/problemi/'
  );
  // const notifica = (title: string, body: string) => {
  //   const options = {
  //     body: body,
  //     icon: "/static/favicon.ico"
  //   }
  //   let cusumano = new Notification(title, options);
  //   cusumano.onclick = (event) => {
  //     event.preventDefault();
  //     console.log(self);
  //     c.openWindow("http://127.0.0.1/thread/problema/")
  //   };
  // }

  ret.onmessage = (e) => {
    const data = JSON.parse(e.data);
    if (data.error) {
      console.error(data.error);
      return;
    }
    if (data.type == "new_notification") {
      postMessage(data);
      // notifica(data.json.title, data.json.body);
    }
  }

  ret.onopen = ((e) => {
    console.log("open")
  });

  ret.onclose = ((e) => console.warn("close"));
  // ret.onopen = ((e) => onopen(e, obj));
  // ret.onclose = ((e) => onclose(e, obj, () =>
  //   get_problema_notifications_ws(obj)));
  return ret;
}


const molesta_categorie_preferite = (io: UserWhoami) => {
  if (io.categorie_preferite.length == 0) {
    const message = {
      type: "categorie_preferite_non_inserite",
      payload: ""
    }
    postMessage(JSON.stringify(message));
  }
}

const notifica_problemi_non_votati = (io: UserWhoami) => {
  if (io.problemi_corretti.length > 0 || io.problemi_proposti.length > 0) {
    get_bellezza({ partecipante: io.id }).then(ans => {
      get_difficolta({ partecipante: io.id }).then(aans => {
        const votati = new Set([...ans, ...aans].map(item => item.problema));
        const corretti = new Set(io.problemi_corretti);
        const proposti = new Set(io.problemi_proposti);
        const mancanti = new Set([...corretti, ...proposti].filter(x => !votati.has(x)));
        if (mancanti.size > 0) {
          get_problema_thread_list().then(lista => {
            const veri_mancanti = [...mancanti].map(item => {
              return lista.find(lista_item => lista_item.problema.id == item);
            });
            const message = {
              type: "problemi_non_votati",
              payload: veri_mancanti,
            }
            postMessage(JSON.stringify(message));
          })
        }
      });
    });
    return {};
  }
}


// const ws = get_problema_notifications_ws();

self.addEventListener('message', event => {
  let message;
  try {
    // @ts-ignore
    message = JSON.parse(event.data);
  } catch (e) {
    return;
  }
  // Aspetta che ci sia un messaggio con le mie informazioni
  if (message.type == "user_detail") {
    const user = message.payload;
    notifica_problemi_non_votati(user);
    molesta_categorie_preferite(user);
  }
});
